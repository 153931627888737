import $http from '../index';

function getDetail(params) {
  const path = '/v1/advertising/detail';
  return $http(path, 'get', params)
}

export default {
  getDetail,
};
